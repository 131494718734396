// 全局变量
let BASE_API = ""
if (process.env.NODE_ENV == "development") {
	BASE_API = "https://www.medcongpt.com"
	// BASE_API = window.location.origin
} else if (process.env.NODE_ENV == "production") {
	BASE_API = window.location.origin
} else if (process.env.NODE_ENV == "test") {
	// BASE_API = 'https://mettgpt.nymaite.cn'
	BASE_API = process.env.VUE_APP_API_URL
}

export { BASE_API }
