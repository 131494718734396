<template>
	<div id="app">
		<keep-alive>
			<component :is="layout">
				<router-view />
			</component>
		</keep-alive>
	</div>
</template>

<script>
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
	export default {
		computed: {
			layout() {
				return "layout-" + (this.$route.meta.layout || "default").toLowerCase()
			}
		},
		created() {
			this.getWebsiteConfigInfo()
			if(window.innerWidth < 768){
				window.location.href = '/h5/';
			}
		},
		methods: {
			...mapActions("app", ["getWebsiteConfigInfo"])
		}
	}
</script>

<style lang="scss"></style>
